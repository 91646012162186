import { useState } from 'react';
import { useOrder } from '../hooks/orderHook';
import { useLocation } from 'react-router-dom';

// Stylesheets
import '../stylesheets/animations.css';
import '../stylesheets/order.css';
import '../stylesheets/content.css';
import '../stylesheets/shapes.css';
import '../stylesheets/photos.css';
import '../stylesheets/gallery.css';

const TriangleShape = (props) => {
    return (
        <div className={`triangle-${props.direction}`}></div>
    )
}

const PriceHeader = (props) => {
    return (
        <p className="pricingHeader">{props.children}</p>
    )
}

const Pricing = (props) => {
    const PricingColumn = (props) => {
        const order = useOrder();
        return (
            <div>
                <div className="pricingColumn">
                    { props.data.map( (row, id) => 
                        <div key={id} className="pricingRow">
                            {row}
                        </div>
                    )}    
                    <button onClick={() => order.show()} className="order">Zamów</button>
                </div>
            </div>
        )
    }

    return (
        <div className="pricingContainer">
            <h1>{props.title}</h1>
            <div className="pricing">
                { props.data.map( (col, id) => <PricingColumn key={id} data={col} />)}
            </div>
        </div>
    )
}

const PolaroidDesctiptionWidget = (props) => {
    return (
        <div className="polaroidWidget">
            <div className="polaroid">
                <div className="printed p1"></div>
            </div>
            <div className="description">{props.content}</div>
        </div>
    )
}

const LetterDescriptionWidget = (props) => {
    let _class = "p2";
    let styles = {};

    if(props.path) 
    {
        _class = "";
        styles = { backgroundImage: `url(${props.path})`}
    }

    return (
        <div className="letterWidget">
            <div className="description">{props.content}</div>
            <div className="letter inverted">
                <div className={`printed2 ${_class}`} style={styles}></div>
            </div>
        </div>
    )
}

const HorizontalWidget = (props) => {
    const Description = () => {
        return (
            <div className="hw-text" style={props.additionalStyle}>
                {props.contents.description}
            </div>
        )
    }

    return (
        <>
            { props.contents.reversed && ( <Description /> ) }
            <div className="hw-photo">
                <img src={props.contents.path} style={{transform: `rotate(${props.tilt})`}} alt={props.contents.alt}/>
            </div>
            { !props.contents.reversed && ( <Description /> ) }
        </>
    )
}


const WideWidget = (props) => {
    const Description = () => {
        return (
            <div className="wide-text" style={props.additionalStyle}>
                {props.contents.description}
            </div>
        )
    }

    return (
        <>
            { props.contents.reversed && ( <Description /> ) }
            <div className="hw-wide">
                <img src={props.contents.path} style={{transform: `rotate(${props.tilt})`}} alt={props.contents.alt}/>
            </div>
            { !props.contents.reversed && ( <Description /> ) }
        </>
    )
}

const Gallery = (props) => {
    const [ no, setNo ] = useState(props.min);

    const prev = () => (no > props.min) ? setNo(no - 1) : setNo(props.max);
    const fwd = () => (no < props.max) ? setNo(no + 1) : setNo(props.min);

    return (
        <>
            <h1>Galeria</h1>
            <div className="gallery">
                <div className="arrow_container">
                    <img onClick={prev} className="arrow" src="./sprites/arrow_left.svg" alt="prev" />
                </div>
                <div className="gallery_view">
                    <img className="gallery_image" src={`./content/${props.gallery}/${no}.webp`} alt="gallery" />
                </div>
                <div className="arrow_container">
                    <img onClick={fwd} className="arrow" src="./sprites/arrow_right.svg" alt="next" />
                </div>
            </div>
        </>
    )
}

export const Aranzacje = () => {
    const cennikSala = [
        [
            <PriceHeader>Wynajem LED'a</PriceHeader>,
            <strong>150zł</strong>,
        ],
        [
            <PriceHeader>Strojenie sali</PriceHeader>,
            <strong>600zł - 1000 zł</strong>,
            "Ścianka z balonami do zdjęć",
            "Balony z obciążnikami na stoły",
            "Balon z wiekiem solenizanta",
            "Napis LED z wiekiem",
            "Dojazd do 30 km gratis"
        ],
        [
            <PriceHeader>Stelaż sześciokątny - wynajem</PriceHeader>,
            <strong>150zł</strong>,
        ]
    ];

    return (
        <>
            <TriangleShape direction="topright" />
            <div className="tile">
                <HorizontalWidget tilt="8deg" contents={{
                    reversed: true,
                    path: './content/dekoracje/dekoracje2.webp',
                    alt: 'Dekoracje sal w Kępnie i okolicach - otofoto.org',
                    description: <>{/*
                        <h1>Profesjonalne dekorowanie sal na imprezy - stworzymy niezapomnianą atmosferę na Twoim evencie!</h1>
                        Jeśli planujesz organizację imprezy, ważnym elementem jest odpowiednie udekorowanie sali. Jeśli nie masz doświadczenia w dekorowaniu lub brakuje Ci pomysłów, warto skorzystać z usług profesjonalnej firmy, która zajmuje się dekorowaniem sal na imprezy. Nasza firma specjalizuje się w dekorowaniu sal, tworząc niezapomniane atmosfery na różnego rodzaju wydarzeniach, takich jak wesela, urodziny, bankiety czy imprezy firmowe.
                        
                        Dysponujemy szerokim wyborem dekoracji, od kwiatów po balony, girlandy czy światła. Nasza załoga dba o każdy szczegół, a dzięki temu zawsze możemy zagwarantować naszym klientom satysfakcję z wykonanej pracy. Jeśli potrzebujesz pomocy w dekorowaniu sali na swoją imprezę, skontaktuj się z nami, a chętnie pomożemy Ci stworzyć wymarzone wnętrze.
                    */}
                    <h1>Tworzymy Magię Twojego Wydarzenia! Dekoracje Sal i Wynajem Napisów LED</h1>
                    
                    Chcesz, aby Twoje wydarzenie było wyjątkowe i niezapomniane? Pozwól nam uczynić to możliwym! Jesteśmy ekspertami w dekorowaniu sal i oferujemy wynajem napisów LED, które dodadzą wyjątkowego blasku Twojej imprezie.

                    </>
                }} />
            </div>
            <div className="tile vertical">
                <Gallery min={1} max={4} gallery="gallery3"/>
            </div>
            <TriangleShape direction="bottomleft" />
            <TriangleShape direction="topright" />
            <div className="tile">
                <HorizontalWidget tilt="-3deg" contents={{
                    reversed: false,
                    path: './content/dekoracje/dekoracje3.webp',
                    alt: 'Dekoracje sal w Kępnie i okolicach - otofoto.org',
                    description: <>
                    <h1>Dlaczego warto nas wybrać?</h1>
                    <ul>
                        <li>Dekoracje Sal: Nasza kreatywna ekipa zadba o każdy detal, aby sala była niepowtarzalna i dopasowana do Twojej wizji.</li>
                        <li>Napisy LED: Podświetl swoje wydarzenie napisami LED, które nadadzą mu niezapomniany charakter.</li>
                        <li>Personalizacja: Każdy projekt jest tworzony z myślą o Tobie. Dostosujemy dekoracje i napisy do Twoich indywidualnych potrzeb.</li>
                        <li>Profesjonalizm: Możesz polegać na naszym doświadczeniu i zaangażowaniu, aby stworzyć wyjątkową atmosferę. Niech Twoje wydarzenie zabłyśnie!</li>
                    </ul>
                    </>
                }} />
            </div>
            <TriangleShape direction="bottomleft" />
            <TriangleShape direction="topright" />
            <Pricing title="" data={cennikSala} />
        </>
    )
}

export const Fotograf = () => {
    const fotografCennik = [
        [
            <PriceHeader>Standard</PriceHeader>,
            <><s>590 zł</s> <strong>399 zł</strong></>,
            "Czas pracy fotografa - 6h",
            "Ilość zdjęć w formie cyfrowej - minimum 100",
            "Osobista galeria internetowa",
            "Pendrive ze zdjęciami",
            "Dojazd gratis do 40 km",
            "Każda dodatkowa godzina - 100 zł"
        ],
        [
            <PriceHeader>Premium</PriceHeader>,
            <><s>899 zł</s> <strong>649 zł</strong></>,
            "Czas pracy fotografa - 8h",
            "Ilość zdjęć w formie cyfrowej - minimum 200",
            "Osobista galeria internetowa",
            "Pendrive ze zdjęciami",
            "Dojazd gratis",
            "Zdjęcia z zimnymi ogniami",
            "Każda dodatkowa godzina - 80zł",
            "Upominek premium od OtoFoto!"
        ]
    ]
    return (
        <>
            <TriangleShape direction="topright" />
            <div className="tile">
                <HorizontalWidget tilt="-7deg" contents={{
                    reversed: false,
                    path: './content/gallery1/25.webp',
                    alt: 'Fotograf',
                    description: <>
                    <h1>Profesjonalna Fotografia Imprezowa - Uwiecznij Wyjątkowe Chwile z Naszym Fotografem</h1>
                    Potrzebujesz fotografa na imprezę? Dobrze trafiłeś! Od zawsze pasjonuję się fotografią i z przyjemnością uwiecznię dla Ciebie ten wyjątkowy dzień. Fotografia to nie tylko zwykłe uchwycenie chwili, to także obrazy, które przywołują wspomnienia ważnych momentów - zarówno celowo, jak i spontanicznie. Zdjęcia pozwalają zachować piękne wspomnienia na długie lata. Skontaktuj się ze mną, aby umówić się na sesję zdjęciową.
                    </>
                
                }} />
            </div>
            <TriangleShape direction="bottomleft" />
            <TriangleShape direction="topright" />
            <Pricing title="Pakiety" data={fotografCennik} />
        </>
    )
}

export const Fotobudka = () => {
    const fotobudkaCennik = [
        [
            <PriceHeader>MICRO</PriceHeader>,
            <><s>400zł</s> <strong>350zł</strong></>,
            "Czas: 1 godzina",
            "Możliwość wyboru tła",
            "Nielimitowana liczba zdjęć",
            "Zdjęcia w wersji elektronicznej",
            "Obsługa fotobudki przez animatora",
            "Personalizacja wydruku",
            "Dojazd do 20 km gratis",
            //"Zdjęcia elektroniczne na pendrive - 40zł",
            //"Księga gości do wklejenia zdjęć - 70 zł",
            "Każda kolejna godzina - 200 zł",
            ""
        ],
        [
            <PriceHeader>MINI</PriceHeader>,
            <><s>600zł</s> <strong>550zł</strong></>,
            "Czas: 2 godziny",
            "Możliwość wyboru tła",
            "Nielimitowana liczba zdjęć",
            "Zdjęcia w wersji elektronicznej",
            "Obsługa fotobudki przez animatora",
            "Personalizacja wydruku",
            "Dojazd do 20 km gratis",
            //"Zdjęcia elektroniczne na pendrive - 40zł",
            //"Księga gości do wklejenia zdjęć - 70 zł",
            "Każda kolejna godzina - 200 zł",
            ""
        ],
        [
            <PriceHeader>STANDARD</PriceHeader>,
            <><s>700zł</s> <strong>650zł</strong></>,
            "Czas: 3 godziny",
            "Możliwość wyboru tła",
            "Nielimitowana liczba zdjęć",
            "Zdjęcia w wersji elektronicznej",
            "Obsługa fotobudki przez animatora",
            "Personalizacja wydruku",
            "Dojazd do 30km gratis",
            //"Zdjęcia elektroniczne na pendrive",
            //"Księga gości do wklejenia zdjęć - 70 zł",
            "Każda kolejna godzina - 200 zł",
            ""
        ],
        [
            <PriceHeader>PREMIUM</PriceHeader>,
            <><s>1000zł</s> <strong>800zł</strong></>,
            "Czas: 4 godziny",
            "Możliwość wyboru tła",
            "Nielimitowana liczba zdjęć",
            "Zdjęcia w wersji elektronicznej",
            "Obsługa fotobudki przez animatora",
            "Personalizacja wydruku",
            "Dojazd do 40km gratis",
            //"Zdjęcia elektroniczne na pendrive",
            //"Księga gości do wklejenia zdjęć",
            "Każda kolejna godzina - 200 zł",
            //"Upominek premium od OtoFoto!"
        ]
    ];

    return (
        <>
            <TriangleShape direction="topright" />
            <div className="tile">
                <HorizontalWidget tilt="-8deg" contents={{
                    reversed: false,
                    path: './content/gallery4/61.webp',
                    alt: 'Wynajem fotobudki na imprezy, wesela, bankiety Kępno i okolice',
                    description: <>{/*
                    <h1>Wynajem Fotobudki na Imprezy - Zdobądź Niezapomniane Wspomnienia z Naszą Fotobudką i Akcesoriami</h1>
                    Wynajem fotobudki to doskonały sposób na urozmaicenie Twojego wydarzenia. Oferujemy urządzenie, które umożliwia robienie wysokiej jakości zdjęć indywidualnych i grupowych, a następnie drukowanie ich na miejscu. W każdym pakiecie zapewniamy obsługę fotobudki przez dedykowanego animatora przez całą imprezę. Istnieje możliwość wyboru tła, które najlepiej pasuje do charakteru Twojego wydarzenia. W naszej galerii znajdziesz przykłady zdjęć wykonanych przez naszą fotobudkę, dzięki czemu możesz zobaczyć, jak doskonale sprawdza się ona na różnych imprezach.'
                    */}
                    <h1>Złap chwilę i zatrzymaj ją na zawsze!</h1>
                    <h2>Wynajmij naszą fotobudkę i stwórz wspaniałe wspomnienia.</h2>
                    Chcesz, aby Twoje wydarzenie było wyjątkowe? Fotobudka to doskonały sposób na uchwycenie uśmiechów, emocji i spontanicznych chwil. Bez względu na to, czy organizujesz ślub, imprezę firmową, czy urodziny, nasza fotobudka dostarczy niezapomnianej rozrywki.
                    </>
                }}/>
            </div>
            <TriangleShape direction="bottomright" />
            <TriangleShape direction="topleft" />
            <div className="tile">
                <HorizontalWidget tilt="8deg" contents={{
                    reversed: true,
                    path: './content/gallery4/9.webp',
                    alt: 'Wynajem fotobudki na imprezy firmowe, osiemnastki, studniówki Kępno i okolice',
                    description: <>
                    <h3>Dlaczego warto nas wybrać?</h3>
                    <ul>
                        <li>Niezwykłe zdjęcia: Twoi goście będą zachwyceni możliwością wykonywania zabawnych i kreatywnych zdjęć.</li>
                        <li>Dodatkowa atrakcja: Fotobudka to element rozrywki, który wszyscy pokochają. To idealny sposób na wzmocnienie atmosfery imprezy.</li>
                        <li>Niestandardowa personalizacja: Dostosujemy tło, ramki i grafikę do Twoich indywidualnych potrzeb, aby każde zdjęcie było wyjątkowe.</li>
                        <li>Natychmiastowe wydruki: Twoi goście otrzymają kopie zdjęć na miejscu, które będą doskonałą pamiątką.</li>
                        <li>Profesjonalna obsługa: Nasza ekipa zadba o wszystko, aby Twoja impreza przebiegła bezproblemowo.</li>
                    </ul>
                    Nie przegap szansy na niezapomnianą rozrywkę! Wynajmij naszą fotobudkę już teraz i stwórz wspomnienia, które zostaną z Tobą i z Twoimi gośćmi na zawsze.</>
                }}/>
            </div>
            <TriangleShape direction="bottomleft" />
            <TriangleShape direction="topright" />
            <div className="tile vertical">
                <Gallery min={1} max={62} gallery="gallery4"/>
            </div>
            <TriangleShape direction="bottomright" />
            <TriangleShape direction="topleft" />
            <Pricing title="Dostępne pakiety" data={fotobudkaCennik} />
        </>
    )
}

export const Dj = () => {
    const djCennik = [
        [
            <PriceHeader key="1">Standard</PriceHeader>,
            "Cena za 8 godzin - 1500zł",
            "Każda kolejna godzina - 150 zł",
            "Dojazd do 60km gratis"
        ]
    ]

    //DJ page
    return (
        <>
            <TriangleShape direction="topright" />
            <div className="tile">
                <WideWidget contents={{
                    reversed: false,
                    path: './content/dj/5.webp',
                    alt: 'DJ Fred - DJ Franciszek Klyta - Oprawa muzyczna imprez - DJ Na osiemnastki, studniówki, imprezy okolicznościowe, wesela, urodziny, uroczystości',
                    description: <>
                    <h1>DJ Fred - oprawa muzyczna Twojej imprezy</h1>
                    Jeśli tutaj trafiłeś, to znaczy, że potrzebujesz DJ-a, który poprowadzi Twoją imprezę w niezapomniany sposób. Oto jestem! Każde przyjęcie to dla mnie nowe wyzwanie, do którego podchodzę indywidualnie. Oferuję nie tylko oprawę muzyczną, lecz także prowadzenie gier i zabaw integracyjnych. Mam olbrzymią bazę utworów oraz wysokiej jakości sprzęt muzyczny i oświetleniowy. Utrzymanie wysokiej jakości dźwięku uzyskuję dzięki korzystaniu ze sprzętów renomowanych marek: Electro-Voice, Shure oraz Pionieer.</>
                }}/>
            </div>
            <TriangleShape direction="bottomleft" />
            <TriangleShape direction="topright" />
            <div className="tile">
                <WideWidget  contents={{
                    reversed: true,
                    path: './content/dj/6.webp',
                    alt: 'DJ Fred - DJ Franciszek Klyta - Oprawa muzyczna imprez - DJ Na osiemnastki, studniówki, imprezy okolicznościowe, wesela, urodziny, uroczystości',
                    description: <div className="simple-description">
                        Jako DJ/Wodzirej oferuję:
                        <ul>
                            <li>Urodziny, rocznice, dyskoteki,</li>
                            <li>studniówki, połowinki,</li>
                            <li>imprezy firmowe,</li>
                            <li>imprezy okolicznościowe.</li>
                        </ul>
                    </div>
                }}/>
            </div>
            <TriangleShape direction="bottomright" />
            <TriangleShape direction="topleft" />
            <div className="tile">
                <WideWidget contents={{
                    reversed: false,
                    path: './content/dj/7.webp',
                    alt: 'DJ Fred - DJ Franciszek Klyta - Oprawa muzyczna imprez - DJ Na osiemnastki, studniówki, imprezy okolicznościowe, wesela, urodziny, uroczystości',
                    description:
                    <>
                    <p>Gwarantuję, że impreza powierzona w moje ręce zostanie poprowadzona w wyjątkowy sposób, co więcej - będzie dopasowana do gustu i stylu gości, ze szczególnym uwzględnieniem osobistych upodobań solenizanta. </p>
                    <p>Na koniec parę słów o mnie: mam na imię Franciszek, jestem dyplomowanym muzykiem. Od najmłodszych lat pasjonuję się tą dziedziną, co przerodziło się w chęć dzielenia się tą umiejętnością z innymi. Mam swoje preferencje muzyczne, nie wpływa to jednak na fakt, że zarówno utwory pochodzące z lat 80. albo 90., jak i aktualnie popularne klimaty popu czy też rapu nie są mi obce.</p>
                    </>
                }}/>
            </div>
            <TriangleShape direction="bottomleft" />
            <TriangleShape direction="topright" />
            <Pricing title="Dostępne pakiety" data={djCennik} />
        </>
    )
}

export const Landing = () => {
    // Landing page
    /*const landingContent = {
        intro: "Witaj na OtoFoto.org! Zajmujemy się profesjonalną obsługa imprez w Kępnie i okolicach. Zapewnimy DJ'a, Fotografa, Fotobudkę a także dekorację Twojego eventu! Każdą z naszych usług możesz wykupić osobno, korzystając z dostępnych pakietów. ",
        polaroid: "Korzystając z naszych usług mają Państwo pewność rzetelnej i profesjonalnej usługi, ponieważ w każdym dostępnym pakiecie gwarantujemy obsługę fotobudki przez animatora przez całą imprezę, dużą ilość gadżetów odpowiednich dla dorosłych jak i dzieci, które urozmaicą i wniosą wiele humoru i zabawy do zdjęcia. Dajemy Państwu możliwość wyboru tła, które jak najbardziej będzie oddawało charakter imprezy. Dzięki nam rozkręcisz każdą imprezę i dasz możliwość swoim gościom zrobienia sobie szalonych fotek.",
        letter: "Dysponujemy szeroką gamą gadżetów i akcesoriów, które z pewnością zmienią zdjęcia w szalone selfi pełne humoru i uśmiechu waszych gości. Możemy pochwalić się szeroką gamą kapeluszy, tabliczek ze śmiesznymi napisami. Jesteśmy przekonani, że większość naszych dodatków rozbawi was do łez i rozkręci imprezę, którą potem będziecie jeszcze długo wspominać. Nasza pamiątka zostanie z wami na długo."
    }*/

    const landingContent = {
        intro: <><h1>Fotobudka na Twoją imprezę w Kępnie i okolicach - usługi OtoFoto.org!</h1>Witaj na stronie OtoFoto.org! Jesteśmy profesjonalną firmą zajmującą się obsługą imprez w Kępnie i okolicach. Oferujemy szeroki wachlarz usług, takich jak: DJ, Fotograf, Fotobudka oraz dekoracja eventów. Dostępne są u nas różne pakiety, z których można skorzystać, a każdą usługę można również wykupić osobno.</>,
        polaroid: "Korzystając z naszych usług, masz pewność rzetelnej i profesjonalnej obsługi fotobudki przez całą imprezę. Oferujemy szeroki wybór pakietów, w których gwarantujemy obsługę przez doświadczonego animatora, który zapewni wiele humoru i zabawy dla dorosłych i dzieci. W każdym pakiecie zapewniamy również duży wybór gadżetów, które urozmaicą Twoje zdjęcia i dodadzą im charakteru. Masz możliwość wyboru tła, które będzie odpowiadało tematyce Twojej imprezy. Z nami każde wydarzenie nabierze tempa, a Twoi goście przeżyją niezapomniane chwile i szaloną zabawę przy robieniu zdjęć. Z pewnością dobierzemy ofertę dostosowaną do Twoich potrzeb!",
        letter: "Nasza oferta zawiera szeroką gamę gadżetów i akcesoriów, które sprawią, że zdjęcia z fotobudki będą pełne humoru i uśmiechu. Posiadamy wiele kapeluszy oraz tabliczek ze śmiesznymi napisami, które na pewno wprowadzą dobry nastrój na Twoją imprezę. Nasi klienci są zawsze zadowoleni z różnorodności naszych dodatków, które wzbogacają zdjęcia z fotobudki. Jesteśmy przekonani, że większość z nich rozbawi was do łez i wprowadzi szaloną atmosferę na imprezę, którą będziecie jeszcze długo wspominać. Korzystając z naszych usług, otrzymasz pamiątkę, która pozostanie z Tobą na długo. Skontaktuj się z nami, a pomożemy Ci wybrać odpowiednie akcesoria i gadżety dla Twojej imprezy."
    }

    return (
        <>
            <TriangleShape direction="topright" />
            <div className="tile">
                <LetterDescriptionWidget path="./content/gallery1/27.webp" content={landingContent.intro}/>
            </div>
            <TriangleShape direction="bottomleft" />
            <TriangleShape direction="topright" />
            <div className="tile">
                <PolaroidDesctiptionWidget content={landingContent.polaroid}/>
            </div>
            <TriangleShape direction="bottomleft" />
            <TriangleShape direction="topright" />
            <div className="tile">
                <LetterDescriptionWidget content={landingContent.letter}/>
            </div>
        </>
    )
};

export const Samochody = () => {
    // Wołga
    return (
        <>
            <TriangleShape direction="topright" />
            <div className="tile">
                <WideWidget contents={{
                    reversed: false,
                    path: './content/samochody/wolga1.webp', // Tu wpierdol zdjęcie
                    alt: 'Czarna wołga',
                    description: <><h2>Czarna wołga</h2>Wynajem okazjonalny samochodu Wołga Gaz-24-01. O tym samochodzie krąży legenda, która brzmi: "Czarna Wołga porywała dzieci", ale te czasy już minęły. Mogą Państwo wynająć tego pięknego klasyka wraz z elegancko ubranym kierowcą na najważniejsze uroczystości: wesela, komunie, sesje zdjęciowe i różne jubileusze. Samochód w obiektywie aparatu prezentuje się pięknie, a jeszcze lepiej na żywo. Wszystkich zainteresowanych prosimy o rezerwację terminów i uzgodnienie najważniejszych kwestii wynajmu.</>
                }}/>
            </div>
            <TriangleShape direction="bottomleft" />
            <TriangleShape direction="topright" />
            <div className="tile vertical">
                <Gallery min={1} max={4} gallery="gallery2"/>
            </div>
        </>
    )
}

export const Order = (props) => {
    const order = useOrder();
    
    const location = useLocation();
    const path = location.pathname.replace("/", "");

    let tel = (path === "dj") ? "572 670 348" : "798 228 227";
    tel = (path === "dekoracje") ? "730 080 154" : tel;

    return (
        <>
            <div className="window" style={{display: order.visibility}}>
                <h1>Zamów pakiet</h1>
                <div className="order_by_email">
                    <a href="mailto:kontakt@otofoto.org">
                        <button className="btn">
                            Napisz email (kontakt@otofoto.org)
                        </button>
                    </a>
                </div>
                <div className="order_by_telephone">
                    <a href={`tel:${tel.replaceAll(" ", "")}`}>
                        <button className="btn">
                            Zadzwoń ({ tel })
                        </button>
                    </a>
                </div>
                <div className="close">
                    <button onClick={() => order.hide()} className="btn">Zamknij</button>
                    </div>
                </div>
        </>
    );
}